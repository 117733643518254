import React, { useState } from 'react';
import { AutoTabProvider } from 'react-auto-tab'
import Cards from "elt-react-credit-cards";
import "elt-react-credit-cards/es/styles-compiled.css";

function CardDetails({ onSubmit }) {
  const [cardName, setCardName] = useState("")
  const [cardNumber, setCardNumber] = useState("")
  const [cardMonth, setCardMonth] = useState("")
  const [cardYear, setCardYear] = useState("")
  const [cardCVV, setCardCVV] = useState("")
  const [alertMsg, setAlertMsg] = useState("")
  const [alertMsgCardNumber, setAlertMsgCardNumber] = useState("")
  const [alertMsgCardCVV, setAlertMsgCardCVV] = useState("")
  const [cardDetails, setCardDetails] = useState([])
  const [isEnabled, setIsEnabled] = useState(false)
  const [data, setData] = useState({
    cvc: "",
    expiry: "",
    name: "",
    number: "",
  });

  const handleSubmit = () => {
    let cardObj = {
      cardName: cardName,
      cardNumber: cardNumber,
      cardCVV: cardCVV,
      cardExpiry: `${cardMonth}/${cardYear}`
    }
    onSubmit(cardObj)
  };

  const handleBlur = (e) => {
    if (cardMonth.length < 0 || cardYear.length < 0 || cardCVV.length < 1 || cardNumber.length < 1) {
      setIsEnabled(false)
    } else {
      setIsEnabled(true)
    }

    if (e.target.value === "") {
      setAlertMsg("Enter Mandatory field");
    } else {
      setAlertMsg("");
    }
  };

  const clear = () => {
    setCardName("");
    setCardNumber("")
    setCardMonth("")
    setCardYear("")
    setCardCVV("")
    setAlertMsg("")
    setAlertMsgCardNumber("")
    setAlertMsgCardCVV("")
    setCardDetails([])
    setIsEnabled(false)
    setData({
      cvc: "",
      expiry: "",
      name: "",
      number: "",
    });
  }

  //to add space in between input cardnumber
  const handleCardDisplay = () => {
    // const regex = /^(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})$/g
    // const onlyNumbers = cardNumber.replace(/[^\d]/g, '')

    // return onlyNumbers.replace(regex, (regex, $1, $2, $3, $4) =>
    //   [$1, $2, $3, $4].filter(group => !!group).join(' ')
    // )

    return cardNumber;
  }

  const setCardNumberInput = (e) => {
    if (Number(e.target.value) || e.target.value == '') {
      setCardNumber(e.target.value)
    }
  }

  const isSubmitEnabled = () => {
    if (cardName != '' && cardName.length >= 3 && cardNumber != "" && cardNumber.length == 16 && cardCVV != "" && cardCVV.length == 3 && (cardMonth != '' && cardMonth >= 1 && cardMonth <= 12) && (cardYear != "" && cardYear >= 22 && cardYear <= 35))
      return true
    else
      return false
  }

  const setCardMon = (e) => {
    // if (e.target.value >= 0 && e.target.value <= 12) {
    //   setCardMonth((v) => (e.target.validity.valid ? e.target.value : v));
    //   setAlertMsg('')
    // }
    // else {
    //   console.log('Here')
    //   setCardMonth('')
    // }

    let monArr = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '01', '02', '03', '04', '05', '06', '07', '08', '09'];
    if (e.target.value.length === 1) {
      setCardMonth(e.target.value)
      setAlertMsg('')
    } else {
      if (monArr.includes(e.target.value)) {
        setCardMonth(e.target.value)
        setAlertMsg('')
      } else {
        setCardMonth('')
      }
    }

  }

  return (
    <div>
      <div>
        <Cards
          cvc={cardCVV}
          expiry={cardMonth + cardYear}
          focus={data.focus}
          name={cardName}
          number={cardNumber}
        />
      </div>
      <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
        <div style={{ width: '95%', }}>
          <div>
            <div>
              <label style={{ marginTop: 13 }}>
                Name on the card
              </label>
            </div>
            <div>
              <input type="text"
                className="form-control"
                name="cardName"
                value={cardName}
                onChange={(e) => setCardName(e.target.value)}
                style={{ fontSize: 16, padding: 3, width: '100%', marginTop: 5, height: 45, borderWidth: 2 }} aria-describedby="emailHelp" />
            </div>
          </div>

          <div style={{ paddingTop: 10 }}>
            <div>
              <label>Card Number <span style={{ color: 'red' }}> * </span>  </label>
            </div>
            <div>
              <input
                type="text"
                className="form-control"
                name="cardNumber"
                value={handleCardDisplay()}
                maxLength={16}
                onChange={(e) => { setCardNumberInput(e); setAlertMsgCardNumber("") }}
                onBlur={(e) => {
                  if (cardMonth.length < 0 || cardYear.length < 0 || cardCVV.length < 1 || cardNumber.length < 1) {
                    setIsEnabled(false)
                  } else {
                    setIsEnabled(true)
                  }
                  if (e.target.value === "") {
                    setAlertMsgCardNumber("Enter Mandatory field");
                  } else if (cardNumber.length != 16) {
                    setAlertMsgCardNumber("Invalid Card Number")
                  } else {
                    setAlertMsgCardNumber("");
                  }
                }}
                style={{ fontSize: 16, padding: 3, width: '100%', marginTop: 5, height: 45, borderWidth: 2 }} />
              <span style={{ fontSize: 12, color: 'red', }}>{alertMsgCardNumber}</span>
            </div>
          </div>

          <div style={{ paddingTop: 10, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ width: '49%', }}>
              <AutoTabProvider>
                <div style={{ display: 'flex' }}>
                  <label>Expiration Date </label>
                  <span style={{ color: 'red' }}> * </span>
                </div>
                <div style={{ border: '1px solid rgb(206 212 218)', height: 45, borderWidth: 2, borderRadius: '0.25rem ' }} >
                  <input
                    type="text"
                    name="month"
                    maxLength={2}
                    placeholder={'mm'}
                    value={cardMonth}
                    pattern="[0-9]*"
                    onChange={(e) => setCardMon(e)}
                    // onChange={(e) => setCardMonth(e.target.value)}
                    onBlur={handleBlur}
                    style={{ border: 'none', outline: 'none', fontSize: 16, width: '50%', height: 40, color: 'rgb(80 84 87)', paddingLeft: '20%' }} tabbable
                  />
                  <span>/</span>
                  <input
                    type="text"
                    name="year"
                    maxLength={2}
                    placeholder={'yy'}
                    value={cardYear}
                    pattern="[0-9]*"
                    onChange={(e) => {
                      if (e.target.value >= 1 && e.target.value <= 35) {
                        setCardYear((v) => (e.target.validity.valid ? e.target.value : v))
                        setAlertMsg('')
                      } else {
                        setCardYear('')
                      }
                    }}
                    onBlur={handleBlur}
                    style={{ border: 'none', outline: 'none', fontSize: 16, height: 30, marginLeft: 15, width: 40, color: 'rgb(80 84 87)' }} tabbable
                  />
                  <div style={{ fontSize: 12, color: 'red', paddingTop: 5 }}>{alertMsg}</div>
                </div>
              </AutoTabProvider>
            </div>
            <div style={{ width: '2%' }} />
            <div style={{ width: '49%' }}>
              <div style={{ display: 'flex' }}>
                <label>CVV </label>
                <span style={{ color: 'red' }}> * </span>
              </div>
              <div style={{ border: '1px solid rgb(206 212 218)', height: 45, borderWidth: 2, borderRadius: '0.25rem ' }} >
                <input
                  type="text"
                  name="month"
                  value={cardCVV}
                  pattern="[0-9]*"
                  maxLength="3"
                  onChange={(e) => { setCardCVV((v) => (e.target.validity.valid ? e.target.value : v)); setAlertMsgCardCVV("") }}
                  onBlur={(e) => {
                    if (cardMonth.length < 0 || cardYear.length < 0 || cardCVV.length < 1 || cardNumber.length < 1) {
                      setIsEnabled(false)
                    } else {
                      setIsEnabled(true)
                    }
                    if (e.target.value === "") {
                      setAlertMsgCardCVV("Enter Mandatory field");
                    } else if (cardCVV.length != 3) {
                      setAlertMsgCardCVV("Invalid CVV");
                    } else {
                      setAlertMsgCardCVV("");
                    }
                  }}
                  style={{ border: 'none', outline: 'none', fontSize: 16, height: 40, marginLeft: 15, width: '90%', color: 'rgb(80 84 87)' }} tabbable
                />
                <div style={{ fontSize: 12, color: 'red', paddingTop: 5 }}>{alertMsgCardCVV}</div>
              </div>
            </div>
          </div>

          <div style={{ paddingTop: 25, display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
            <div>
              <button className="btn btn-primary" style={{ margin: 4, backgroundColor: 'red', width: '100%' }} onClick={clear}> Clear </button>
            </div>
            <div style={{ width: '10%' }} />
            <div>
              {isSubmitEnabled() ?
                <button className="btn btn-primary" style={{ margin: 4, backgroundColor: '#264aa9', width: '100%' }} onClick={() => handleSubmit()} >Submit</button>
                :
                <button className="btn btn-primary" style={{ margin: 4, backgroundColor: '#264aa9', width: '100%' }} disabled onClick={() => handleSubmit()}>Submit</button>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CardDetails;